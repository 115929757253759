import React from 'react'
import { navigate } from 'gatsby'

const Docs = ({ data }) => {
  
  if (typeof window !== "undefined") navigate('/docs/nzk-games/get-started')
  return null
}

Docs.propTypes = {

}

Docs.defaultProps = {

}

// export const pageQuery = graphql`
//   query {
//     allMdx {
//       edges {
//         node {
//           frontmatter {
//             slug
//             title
//           }
//         }
//       }
// 
//     }
//   }
// `

export default Docs
